// 成本汇总
<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Select v-model="departmentType"
                    placeholder="机构类型"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in departmentTypeArr"
                      :value="item.name"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <!-- <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          search
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect> -->
            <template v-if="currentTabIndex===1">
              <Select v-model="carCostType"
                      placeholder="费用类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in carCostTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="carType"
                      placeholder="车辆类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in typeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            :data="carTreeArr"
                            search
                            checked
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            @onCheckChange="onChangeName">
              </MyTreeSelect> -->
            </template>
            <template v-if="currentTabIndex===2">
              <Select v-model="personCostType"
                      placeholder="费用类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in personCostTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="workType"
                      clearable
                      placeholder="工种"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in workTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===3">
              <Select v-model="operateCostType"
                      placeholder="费用类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in operateCostTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <DatePicker :value="startDate"
                        type="month"
                        placeholder="选择起始月份"
                        @on-change="onChangeStartDate"
                        class="m-r-10"
                        style="width:145px"></DatePicker>
            <DatePicker :value="endDate"
                        type="month"
                        placeholder="选择结束月份"
                        @on-change="onChangeEndDate"
                        class="m-r-10"
                        style="width:145px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/costSummary/query'])"
                    @click="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/costSummary/query'])"
                    @click="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="section">
        <div class="section-title">
          <span>费用汇总</span>
        </div>
        <ul class="section-list">
          <li class="section-item flex-1"
              v-for="(item,index) in summaryArr"
              :key="index">
            <span class="fs-14">{{item.costTypeName}}</span>
            <span class="fs-48 m-l-8">{{item.costMoney}}</span>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="section-title">
          <span>总费用走势</span>
        </div>
        <Echarts height="400px"
                 :options="totalOptions"></Echarts>
      </div>
      <div class="section">
        <div class="section-title">
          <span>各机构成本</span>
        </div>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getListData"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import Echarts from '@/components/common/Echarts';
import { mapActions, mapGetters } from 'vuex';
import { checkPermi } from '@/utils/permission'
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
export default {
  name: 'CostSummary',
  components: { Echarts, MyTreeSelect },
  data () {
    return {
      echartsSeriesData: [], // echarts Series 数据
      echartsXAxisData: [], // echarts XAxis 数据
      currentTabIndex: 0,
      summaryArr: [],
      costTypeName: '',
      costMoney: '',
      tabArr: [
        {
          name: '总成本'
        },
        {
          name: '车辆成本'
        },
        {
          name: '人员成本'
        },
        {
          name: '运营成本'
        },
      ],
      departmentType: '',
      departmentTypeArr: [
        {
          id: 1,
          name: '全部'
        },
        {
          id: 2,
          name: '项目'
        },
        {
          id: 3,
          name: '标段'
        },
        {
          id: 4,
          name: '部门'
        },
        {
          id: 5,
          name: '班组'
        },
      ],
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departmentName: '',
      departmentId: '',
      carCostType: '',
      carCostTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '年检费'
        },
        {
          id: 2,
          name: '保险费'
        },
        {
          id: 3,
          name: '保养费'
        },
        // {
        //   id: 4,
        //   name: '车杂费'
        // },
        {
          id: 5,
          name: '油费'
        },
        {
          id: 6,
          name: '维修费'
        },
        {
          id: 7,
          name: '违章费'
        }
      ],
      carType: '',
      carNumber: '',
      carNumberId: '',
      personCostType: '',
      personCostTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '人员杂费'
        }, {
          id: 2,
          name: '工资组成'
        },
      ],
      workType: '',
      operateCostType: '',
      operateCostTypeArr: [
        {
          id: 0,
          name: '全部'
        }, {
          id: 1,
          name: '电费'
        }, {
          id: 2,
          name: '水费'
        }, {
          id: 3,
          name: '运营杂费'
        },
      ],
      startDate: '',
      endDate: '',
      totalCostArr: [
        {
          title: '总成本',
          value: '900'
        },
        {
          title: '车辆成本',
          value: '200'
        },
        {
          title: '人员成本',
          value: '300'
        },
        {
          title: '运营成本',
          value: '400'
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      totalListData: [],
      columnsTotal: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'departName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '机构类型',
          key: 'orgType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '月份',
          key: 'month',
          tooltip: true,
          align: 'center'
        },
        {
          title: '总成本(元)',
          key: 'cost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆成本(元)',
          key: 'carCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '人员成本(元)',
          key: 'empCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '运营成本(元)',
          key: 'opeCost',
          tooltip: true,
          align: 'center'
        },
      ],
      carListData: [],
      columnsCar: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '月份',
          key: 'costTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '油费(元)',
          key: 'oilCost',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '加油量(L)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '维修费(元)',
          key: 'repairCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保养费(元)',
          key: 'maintainCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '年检费(元)',
          key: 'inspectionCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '保险费(元)',
          key: 'insuranceCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违章费(元)',
          key: 'violationCost',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车杂费(元)',
          key: 'otherFees',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '过路过桥费(元)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '洗车费(元)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '充电费(元)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '加水费(元)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '其它费用(元)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
      ],
      personListData: [],
      columnsPerson: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          fixed: 'left',
          width:140,
          align: 'center'
        },
        {
          title: '机构类型',
          key: 'orgType',
          tooltip: true,
          width:140,
          align: 'center'
        },
        {
          title: '月份',
          key: 'month',
          width:110,
          tooltip: true,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'totalsalary',
          tooltip: true,
          width:90,
          align: 'center'
        },
        {
          title: '工资组成-基础工资(元)',
          key: 'basicsalary',
          tooltip: true,
          width:160,
          align: 'center'
        },
        {
          title: '工资组成-岗位工资(元)',
          key: 'postsalary',
          tooltip: true,
          width:160,
          align: 'center'
        },
        {
          title: '工资组成-绩效考核(元)',
          key: 'otherpay',
          tooltip: true,
          width:160,
          align: 'center'
        },
        {
          title: '工资组成-补贴(元)',
          key: 'subsidy',
          tooltip: true,
          width:130,
          align: 'center'
        },
        {
          title: '工资组成-社保(元)',
          key: 'socialsecurity',
          tooltip: true,
          width:130,
          align: 'center'
        },
        {
          title: '工资组成-公积金(元)',
          key: 'providentfund',
          tooltip: true,
          width:140,
          align: 'center'
        },
        {
          title: '工资组成-个税(元)',
          key: 'incometax',
          tooltip: true,
          width:130,
          align: 'center'
        },
        {
          title: '工资组成-扣款(元)',
          key: 'deduction',
          tooltip: true,
          width:130,
          align: 'center'
        },
      ],
      operateListData: [],
      columnsOperate: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'depName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '机构类型',
          key: 'depTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '月份',
          key: 'monthOfYear',
          tooltip: true,
          align: 'center'
        },
        {
          title: '总费用(元)',
          key: 'allCostNum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '水费(元)',
          key: 'waterCostNum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '电费(元)',
          key: 'electricCostNum',
          tooltip: true,
          align: 'center'
        },
        {
          title: '运营杂费(元)',
          key: 'otherCostNum',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
    };
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'typeArr': 'getCarTypeAllList',
      'carTreeArr': 'getCarTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsTotal
          break;
        case 1:
          columns = this.columnsCar
          break;
        case 2:
          columns = this.columnsPerson
          break;
        case 3:
          columns = this.columnsOperate
          break;
        default:
          break;
      }
      return columns
    },
    getListData () {
      let listData = []
      switch (this.currentTabIndex) {
        case 0:
          listData = this.totalListData
          break;
        case 1:
          listData = this.carListData
          break;
        case 2:
          listData = this.personListData
          break;
        case 3:
          listData = this.operateListData
          break;
        default:
          break;
      }
      return listData
    },
    totalOptions () {
      let totalOptions = {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 40
          },
          data: []
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderWidth: '1',
          borderColor: 'gray',
          textStyle: {
            color: 'black'
          },
          formatter: '',
        },
        series: [],
        color: ['#25bb96'],
      }
      totalOptions.series = this.echartsSeriesData
      totalOptions.xAxis.data = this.echartsXAxisData
      return totalOptions
    },
    typeArr () {
      let arr = [{
        id: '0',
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    workTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getWorkTypeAllList)
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updateWorkTypeAllList',
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updateCarTreeList()
      this.updateCarTypeAllList()
      this.updateWorkTypeAllList()
      this.getList()
      // this.getCostDictionariesList()
    },
    // 总成本数据
    getTotalCostData () {
      let params = {
        size: this.pageSize,
        page: this.pageNum,
        depTypeName: this.departmentType, // 机构类型
        depId: this.departmentId,  // 机构ID
      }
      if (this.startDate) {
        params.startDate = this.startDate + "-01"
      }
      if (this.endDate) {
        params.endDate = this.endDate + "-01"
      }
      this.getTotalCostEcharts(params)
      this.getTotalCostList(params)
    },
    // 总成本 echars
    getTotalCostEcharts (params) {
      this.$http.getTotalCostEcharts(params).then(res => {
        if (res.code == 200) {
          let arr = [{
            name: '总成本',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '车辆成本',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '人员成本',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []

          },
          {
            name: '运营成本',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          }]
          let date = []
          let num = 0
          let carNum = 0
          let personNum = 0
          let otherNum = 0
          let datas = res.result
          arr.forEach((item, index) => {
            datas.forEach((item1, index1) => {
              item.data.push(item1[index + 1])
            });
          });
          datas.forEach(item => {
            num += parseInt(item[1])
            carNum += parseInt(item[2])
            personNum += parseInt(item[3])
            otherNum += parseInt(item[4])
            date.push(item[0])
          });
          this.costMoney = num == 0 ? 0 : num
          this.echartsSeriesData = arr
          this.echartsXAxisData = date
          this.costTypeName = '总成本'
          this.summaryArr = [{
            costTypeName: '总成本',
            costMoney: num == 0 ? 0 : num
          }, {
            costTypeName: '车辆成本',
            costMoney: carNum == 0 ? 0 : carNum
          }, {
            costTypeName: '人员成本',
            costMoney: personNum == 0 ? 0 : personNum
          }, {
            costTypeName: '运营成本',
            costMoney: otherNum == 0 ? 0 : otherNum
          },]
        }
      })
    },
    // 总成本 列表
    getTotalCostList (params) {
      this.$http.getTotalCostList(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.totalListData = data.rows
          this.totalPage = data.total
        }
      })
    },
    // 车辆数据
    getCarCostData () {
      let params = {
        size: this.pageSize,
        page: this.pageNum,
        depTypeName: this.departmentType, // 机构类型
        depId: this.departmentId,  // 机构ID
        costType: this.carCostType,  // 车辆费用类型,  
        carType: this.carType,   // 车辆类型ID
        carName: this.carNumber, // 车牌号ID
      }
      if (this.startDate) {
        params.startDate = this.startDate + "-01"
      }
      if (this.endDate) {
        params.endDate = this.endDate + "-01"
      }
      this.getCarCostEcharts(params)
      this.getCarCostList(params)
    },
    // 车辆 echars
    getCarCostEcharts (params) {
      this.$http.getCarCostEcharts(params).then(res => {
        if (res.code == 200) {
          let arr = [{
            name: '全部',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '年检费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '保险费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '保养费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '油费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '维修费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '违章费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          }]
          let date = []
          let num = 0
          let datas = res.result
          datas.forEach(item => {
            num += parseInt(item[1])
            date.push(item[0])
          });
          arr.forEach((item, index) => {
            datas.forEach((item1, index1) => {
              item.data.push(item1[index + 1])
            });
          });
          this.costTypeName = '车辆成本'
          this.costMoney = num == 0 ? 0 : num
          this.echartsSeriesData = arr
          this.echartsXAxisData = date
          this.summaryArr = [{
            costTypeName: '车辆成本',
            costMoney: num == 0 ? 0 : num
          }]
        }
      })
    },
    // 车辆 列表
    getCarCostList (params) {
      this.$http.getCarCostList(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.carListData = data.rows
          this.totalPage = data.total
        }
      })
    },
    // 人员数据
    getEmployeesCostData () {
      let params = {
        size: this.pageSize,
        page: this.pageNum,
        depTypeName: this.departmentType, // 机构类型
        depId: this.departmentId,  // 机构ID
        costType: this.personCostType, // 人员费用类型
        workType: this.workType,   // 人员工种
      }
      if (this.startDate) {
        params.startDate = this.startDate + "-01"
      }
      if (this.endDate) {
        params.endDate = this.endDate + "-01"
      }
      this.getEmployeesCostEcharts(params)
      this.getEmployeesCostList(params)
    },
    // 人员 echars
    getEmployeesCostEcharts (params) {
      this.$http.getEmployeesCostEcharts(params).then(res => {
        if (res.code == 200) {
          let arr = [{
            name: '全部',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '人员杂费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '工资组成费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          }]
          let date = []
          let num = 0
          let datas = res.result
          datas.forEach(item => {
            num += parseInt(item[1])
            date.push(item[0])
          });
          arr.forEach((item, index) => {
            datas.forEach((item1, index1) => {
              item.data.push(item1[index + 1])
            });
          });
          this.costTypeName = '人员成本'
          this.costMoney = num == 0 ? 0 : num
          this.echartsSeriesData = arr
          this.echartsXAxisData = date
          this.summaryArr = [{
            costTypeName: '人员成本',
            costMoney: num == 0 ? 0 : num
          }]
        }
      })
    },
    // 人员 列表
    getEmployeesCostList (params) {
      this.$http.getEmployeesCostList(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.personListData = data.rows
          this.totalPage = data.total
        }
      })
    },
    // 运营数据
    getOperateCostData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        depTypeName: this.departmentType, // 机构类型
        depId: this.departmentId,  // 机构ID
        costType: this.operateCostType, // 运营费用类型
      }
      if (this.startDate) {
        params.startDate = this.startDate + "-01"
      }
      if (this.endDate) {
        params.endDate = this.endDate + "-01"
      }
      this.getOperateCostEcharts(params)
      this.getOperateCostList(params)
    },
    // 运营 echars
    getOperateCostEcharts (params) {
      this.$http.getOperateCostEcharts(params).then(res => {
        if (res.code == 200) {
          let arr = [{
            name: '全部',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '电费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '水费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '运营杂费',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: []
          }]
          let date = []
          let num = 0
          let datas = res.result
          datas.forEach(item => {
            num += parseInt(item[1])
            date.push(item[0])
          });
          arr.forEach((item, index) => {
            datas.forEach((item1, index1) => {
              item.data.push(item1[index + 1])
            });
          });
          this.costTypeName = '运营成本'
          this.costMoney = num == 0 ? 0 : num
          this.echartsSeriesData = arr
          this.echartsXAxisData = date
          this.summaryArr = [{
            costTypeName: '运营成本',
            costMoney: num == 0 ? 0 : num
          }]
        }
      })
    },
    // 运营 列表
    getOperateCostList (params) {
      this.$http.getOperateCostList(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.operateListData = data.rows
          this.totalPage = data.total
        }
      })
    },
    // 费用类型
    getCostDictionariesList () {
      let params = {
        menuid: 0
      }
      this.$http.getCostDictionariesList(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          data.forEach((item, index) => {
            let obj = { id: index + 1, name: item.costtype }
            if (item.type == 0) {
              this.carCostTypeArr.push(obj)
            } else if (item.type == 1) {
              this.personCostTypeArr.push(obj)
            } else {
              this.operateCostTypeArr.push(obj)
            }
          })
        }
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 修改车牌号 
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 当天时间
    todayDate () {
      return formatDate(new Date())
    },
    onChangeStartDate (date) {
      this.startDate = date
    },
    onChangeEndDate (date) {
      this.endDate = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // tabs 切换
    onClickTabs () {
      this.onClickReset()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          this.getTotalCostData()
          break;
        case 1:
          this.getCarCostData()
          break;
        case 2:
          this.getEmployeesCostData()
          break;
        case 3:
          this.getOperateCostData()
          break;
        default:
          break;
      }
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.totalPage = 0
      this.departmentName = ''
      this.departmentId = ''
      this.departmentType = ''
      this.endDate = ''
      this.startDate = ''
      this.carCostType = ''  // 车辆费用类型
      this.carType = ''  // 车辆类型
      this.carNumber = ''
      this.carNumberId = '' // 车牌号id

      this.workType = ''   // 人员工种
      this.personCostType = '' // 人员费用类型

      this.operateCostType = '' // 运营费用类型
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 10px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        margin-left: 100px;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>